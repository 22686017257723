/*-----------------------------------------------------------------------------------*/
/*  Config
/*-----------------------------------------------------------------------------------*/

/*html {
	font-size: 100%;
}*/


/* `Color
-------------------------------------------------------------*/

$base-color: #ABC2D5;
$text-color: #1C2F42;
$dark-color: #1C2F42;
$black-color: #000000;
$white-color: #FFFFFF;

/* `Type
-------------------------------------------------------------*/
$font-family-base: "freight-text-pro", serif;
$font-family-second: "futura-pt", sans-serif;

$font-size-small: 15px;
$font-size-base: 20px;
$font-size-middle: 22px;
$font-size-big: 25px;

$font-size-title-small: 25px;
$font-size-title: 45px;

$font-weight-base: 300;
$font-weight-middle: 500;
$font-weight-big: 700;

$line-height-base: 1.2;
$line-height-small: 1;

::selection { background: #f2f2f2; }


/* `Responsive
-------------------------------------------------------------*/
// Small tablets and large smartphones (landscape view)
$screen-sm-min: 550px;

// Small tablets (portrait view)
$screen-md-min: 750px;

// Tablets and small desktops
$screen-lg-min: 1000px;

// Large tablets and desktops
$screen-xl-min: 1280px;

// very Large desktops
$screen-xxl-min: 1700px;


// Small tablets and large smartphones (landscape view)
@mixin sm{
  @media (min-width: $screen-sm-min){
    @content;
  }
}

// Small tablets (portrait view)
@mixin md{
  @media (min-width: $screen-md-min){
    @content;
  }
}

// Tablets and small desktops
@mixin lg{
  @media (min-width: $screen-lg-min){
    @content;
  }
}

// Large tablets and desktops
@mixin xl{
  @media (min-width: $screen-xl-min){
    @content;
  }
}

// very Large desktops
@mixin xxl{
  @media (min-width: $screen-xxl-min){
    @content;
  }
}
