/*-----------------------------------------------------------------------------------*/
/*  My reset
/*-----------------------------------------------------------------------------------*/

/*
 * Remove the gap between audio, canvas, iframes,
 * images, videos and the bottom of their containers:
 * https://github.com/h5bp/html5-boilerplate/issues/440
 */

audio, canvas, iframe, img, svg, video {
    vertical-align: middle;
}

/*
 * Remove default fieldset styles.
 */

fieldset, button {
    border: 0;
    margin: 0;
    padding: 0;
}

/*
 * Allow only vertical resizing of textareas.
 */

textarea {
    resize: vertical;
}


.mobile__hide, .mobileGhosts__hide{
	display: none;
	visibility: hidden;
}

button{
	padding: 0;
	margin: 0;
}

ul{
    list-style: none;
    margin: 0;
    padding: 0;
}

a{
	text-decoration: none;
  color: #1C2F42;
}

p, h1, h2, h3, h4, h5, h6{
  margin: 0;
  padding: 0;
}

//@extend %clearfix;
%clearfix {
  &:after {
    content: "";
    display: table;
    clear: both;
  }
}
