/*-----------------------------------------------------------------------------------*/
/*  Layout
/*-----------------------------------------------------------------------------------*/

/* `Layout
-------------------------------------------------------------*/

// HEADER

.header {
  width: 100%;
  position: fixed;
  top: 0; left: 0; right: 0;
  /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#ffffff+0,ffffff+100&1+0,0+100;White+to+Transparent */
  background: -moz-linear-gradient(top, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(top, rgba(255,255,255,1) 0%,rgba(255,255,255,0) 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom, rgba(255,255,255,1) 0%,rgba(255,255,255,0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#00ffffff',GradientType=0 ); /* IE6-9 */
  z-index: 100;
  max-width: 100% !important;
  transform: translateY(0);
  transition: transform .4s ease;
  @media (max-width: 768px){
    background: #FFFFFF;
  }
  a {
    text-decoration: none !important;
  }
  .menu {
    @media (max-width: 768px) {
      padding: 0 20px;
      margin-bottom: 20px;
      a {
        font-size: 13px;
      }
    }
  }
  .logo {
    @media (max-width: 768px) {
      text-align: center;
      width: 100%;
      display: block;
    }
    img {
      transition: width .2s ease;
      width: 190px;
      @media (max-width: 768px) {
        width: 120px !important;
      }
    }
  }
  &.scrolled {
    .logo {
      img {
        width: 120px;
      }
    }
  }
  &.hideMore {
    @media (max-width: 768px){
      transform: translateY(-110px);
    }
  }
}

main {
  margin-top: 200px;
}

.footer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  ul {
    display: flex;
    margin-top: 40px;
    li {
      margin-right: 20px;
      @media (min-width: 992px) {
        margin-right: 50px;
      }
      &:last-child {
        margin-right: 0;
      }
      img {
        height: 80px;
        @media (min-width: 992px) {
          height: 120px;
        }
      }
    }
  }
}

.div{
  margin: 0 0 50px;
  padding: 0 0 50px;
  &--estimate{
    position: relative;
    padding: 0;
    margin: 0;
    &:after {
      content: " ";
      position: absolute;
      top: 0; left: 0; right: 0; bottom: 0;
      width: 100%; height: 100%;
      background: linear-gradient(180deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%);
    }
    * {
      z-index: 10;
    }
  }
  &--best{
    margin: 100px 0 50px;
    padding: 0 0 50px;
  }
  &--visits{
    @extend %clearfix;
  }
}

.italic {
  font-style: italic;
  font-weight: $font-weight-big;
}

.menu{
  font-size: $font-size-small;
  font-family: $font-family-second;
  font-weight: $font-weight-middle;
  text-transform: uppercase;
  margin-top: 30px;
  width: 100%;
  & ul{
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    //flex-direction: column;
    justify-content: space-between;
    & li{
      border-bottom: 1px solid white;
      transition: all .2s ease;
      &:hover, &:focus{
        border-bottom: 1px solid $dark-color;
        transition: all .2s ease;
      }
      a {
        color: $text-color !important;
        &.fb-link {
          img {
            height: 15px;
          }
        }
      }
    }
  }
}

.logo{
  margin: 0 auto;
}

.section{
  position: relative;
  &:after{
    content: "";
    display: block;
    position: absolute;
    bottom: auto;
    top: 50px;
    left: 50%;
    transform: translateX(-50%);
    height: calc(100% - 140px);
    width: 110%;
    background-color: $base-color;
    z-index: 0;
    opacity: .27;
  }
  & div{
    position: relative;
    z-index: 2;
  }
  &--middle:after{
    height: 55%;
    @media (max-width: 768px) {
      height: 40% !important;
    }
  }
  &--small:after{
    height: 35%;
    bottom: 30%;
  }
  & + .section {
    margin-top: 100px !important;
  }
  &.no-bg {
    &:after {
      background-color: transparent !important;
    }
  }
  &-bien-vendu {
    .row-img-container {
      margin-top: 100px;
      @media (max-width: 992px) {
        margin-top: 35px;
      }
    }
    .img-container {
      position: relative;
      margin-bottom: 50px;
      &-up {
        margin-top: 50px;
        @media (max-width: 992px) {
          margin-top: 0;
        }
      }
      .tag {
        position: absolute;
        top: -10px;
        left: 15px;
        background-color: $dark-color;
        color: $white-color;
        font-size: 16px;
        padding: 5px 10px;
        font-style: italic;
      }
    }
  }
  &.with-bg {
    &:after{
      content: "";
      display: block;
      position: absolute;
      bottom: auto;
      top: 300px;
      left: 50%;
      transform: translateX(-50%);
      height: calc(100% - 300px);
      width: 110%;
      background-color: $base-color;
      z-index: 0;
      opacity: .27;
    }
  }
  &-meeting {
     &:after {
       @media (max-width: 992px) {
         height: calc(100% + 75px) !important;
         top: -75Px !important;
       }
     }
   }
  &-mission {
    &:after {
      @media (max-width: 992px) {
        height: calc(100% - 90px) !important;
      }
    }
  }
}

/* `Listes
-------------------------------------------------------------*/
.list{
  display: flex;
  width: 100%;
  &--best, &--team{
    flex-wrap: wrap;
    justify-content: space-between;
    text-align: center;

    & li{
      width: 45%;
      margin-bottom: 20px;
      min-height: 113px;
      & img{
        width: 60px;
        height: 60px;
        display: block;
        margin: 0 auto;
        margin-bottom: 5px;
      }
      &:last-child{
        margin-bottom: 0;
      }
    }
  }
  &--team{
    justify-content: space-around;
    & li{
      width: 50%;
      & img{
        width: 120px;
        height: auto;
      }
    }
  }
  &--services, &--agencies{
    flex-wrap: nowrap;
    flex-direction: column;
    margin-top: 50px;
    & li{
      margin-bottom: 10px;
      &:last-child{
        margin-bottom: 0;
      }
    }
  }
  &--services{
    & li{
      position: relative;
      & p{
        padding-left: 50px;
      }
      &:before{
        content: "";
        display: block;
        position: absolute;
        top: 15px;
        left: 0;
        width: 30px;
        height: 1px;
        background-color: $dark-color;
      }
    }
  }
  &--agencies{
    & li{
      &:first-child{
        font-weight: $font-weight-big;
      }
      & span{
        padding-right: 10px;
        font-weight: $font-weight-middle;
        font-family: $font-family-second;
      }
      & a{
        color: $text-color;
      }
    }
    &:first-child{
      margin-top: 0;
    }
  }
}

// TEAM

.div--team {
  .row {
    .team-member:nth-child(odd){
      @media (min-width: 992px) {
        .team-container {
          margin-top: 75px;
        }
      }
    }
    .team-member {
      @media (max-width: 991px) {
        .team-container {
          margin-top: 0;
          margin-bottom: 25px;
        }
      }
    }
  }
}

.team-container {
  width: 100%;
  height: auto;
  position: relative;
  overflow: hidden;
  img {
    display: block;
    width: 100%;
  }

  &:after{
    content: "";
    display: block;
    width: 100%;
    height: 120px;
    position: absolute;
    z-index: 2;
    bottom: 0;
    left: 0;
    opacity: 1;
    background: linear-gradient(to bottom, rgba(85,99,114,0) 0%, rgba(85,99,114,1) 100%);
  }
  & p{
    position: absolute;
    bottom: 50px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 3;
    color: $white-color;
    width: 80%;
    text-align: center;
    font-size: $font-size-small;
  }
  & .job{
    bottom: 10px;
    font-weight: $font-weight-big;
  }
}

/* `Images
-------------------------------------------------------------*/
.img{
  height: auto;
  width: 100%;
}


/* `Responsive
-------------------------------------------------------------*/
@include sm{
}

@include md{
  .list{
    &--team, &--best{
      justify-content: space-around;
      & li{
        width: 33%;
      }
    }
    &--agencies{
      width: 45%;
      &:last-child{
        margin-top: 0;
      }
    }
    &--services{
      width: 45%;
      margin: 0;

    }
  }

  .div{
    &--agencies{
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
    }
  }

  .services_list{
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
  }

  .img{
    &--services{
      width: 50%;
      height: 50%;
    }
  }

}

@include lg{
  .div{
    padding: 0 0 75px;
    margin: 0 0 75px;
    &--agencies{
      width: 80%;
      margin: 0 auto 100px;
      padding: 0;

    }
    &--estimate{
      margin: 0;
      z-index: 1;
      padding: 0;
    }
    &--meeting{
      display: flex;
      flex-wrap: nowrap;
      justify-content: space-between;
      & h2{
        width: 30%;
      }
    }
    &--best{
        padding: 0 0 75px;
        margin: 150px 0 75px;
    }
  }

  .img{
    &--visits{
      width: 60%;
      float: right;
    }
    &--estimate {
      object-fit: cover !important;
      height: calc(100vh - 250px) !important;
      display: block !important;
      width: 100%;
    }
  }

  .list{
    &--best{
      width: 80%;
      margin: 0 auto;
      & li{
        width: 21%;
      }
    }
    &--services{
      & li{
        margin-bottom: 20px;
        &:before{
          width: 40px;
        }
        & p{
          padding-left: 60px;
        }
      }
    }
    &--team{
      margin-top: 200px;
      flex-direction: column;
      flex-wrap: wrap;
      height: 800px;
      justify-content: flex-start;
      width: 100%;
      overflow-X: hidden;
      & li {
        position: relative;
        width: 22%;
        margin: 0 4% 50px 0;
        overflow-x: hidden;
        &:first-child{
          margin: 200px 4% 200px 0;
        }
        &:nth-child(4){
          margin: -100px 4% 50px 0;
        }
        &:nth-child(6){
          margin: 50px 0 50px 0;
        }
        &:last-child{
          margin-right: 0;
        }
        &:after{
          content: "";
          display: block;
          width: 250px;
          height: 150px;
          position: absolute;
          z-index: 2;
          bottom: 0;
          left: 0;
          background: linear-gradient(to bottom, rgba(28,47,66,0) 0%, rgba(28,47,66,0.58) 34%, rgba(28,47,66,1) 100%);
        }
        & img{
          width: 100%;
          position: relative;
          z-index: 1;
        }
        & p{
          position: absolute;
          bottom: 30px;
          left: 50%;
          transform: translateX(-50%);
          z-index: 3;
          color: $white-color;
          width: 95%;
          font-size: $font-size-small;
        }
        & .job{
          bottom: 10px;
          font-weight: $font-weight-big;
        }
      }
    }
  }

  .menu{
    max-width: 350px;
    & ul{
      height: auto;
    }
  }

  .logo{
    margin: 0;
  }

}

@include xl{
  .div--meeting h2{
    width: 25%;
  }
  .list--team{
    height: 900px;
  }
}
