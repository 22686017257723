/*-----------------------------------------------------------------------------------*/
/*  Type
/*-----------------------------------------------------------------------------------*/
body{
  line-height: $line-height-base;
  font-size: $font-size-base;
  font-weight: $font-weight-base;
  color: $text-color;
  font-family: $font-family-base;
}

header, footer{
  width: 84%;
  margin: 0 auto;
  max-width: 1080px;
}

header{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

main{
  margin: 60px auto 100px;
  width: 84%;
  max-width: 1080px;
}

footer{
  & p{
    width: 100%;
    text-align: center;
  }
  font-size: $font-size-small;
  font-weight: $font-weight-big;
  margin-bottom: 60px;
}

/* `Link & btn
-------------------------------------------------------------*/
.btn, input[type='submit']{
  font-family: $font-family-second !important;
  font-size: 18px;
  font-weight: $font-weight-middle;
  text-align: center;
  background-color: $dark-color;
  color: $white-color;
  padding: 0;
  border-radius: 40px;
  margin: 20px auto;
  line-height: 73px;
  display: block;
  width: 290px;
  transition: all .2s ease;

  &:hover{
    box-shadow: 5px 5px 10px 0px rgba(28,47,66,0.3);
    transition: all .2s ease;
    color: #FFFFFF !important;
  }
}

/* `Title
-------------------------------------------------------------*/
.title{
  font-size: $font-size-title-small;
  font-weight: $font-weight-base;
  text-align: center;
  margin: 0 auto 50px;
  max-width: 400px;
  &--nul{
    font-size: 0px;
    opacity: 0;
    display: none;
  }
  &--estimate{
    margin: 50px auto;
  }
}

/* `Form
-------------------------------------------------------------*/
.form{
  @extend %clearfix;
  & div{
    position: relative;
  }
  & label{
    font-weight: $font-weight-big;
  }
  & input{
    //height: 30px;
    width: 100%;
    padding: 50px 0 0;
    border: 0;
    border-bottom: 1px solid $dark-color;
    margin: 0 0 20px;
    border-radius: 0px;
    background-color: rgba(0, 0, 0, 0) !important;
    &:focus{
      outline: none;
    }
    &[type=checkbox]{
      margin: 0 10px 0 0;
      width: 10%;
      border: 0;
      padding: 0;
    }
    &[type=submit]{
      margin: 50px 0;
      height: 73px;
      width: 290px;
      border: 0;
      padding: 0;
      border-radius: 40px;
      background-color: $dark-color !important;
      color: $white-color;
    }
  }
  & p{
    clear: both;
    text-align: center;
  }
}

input + label.anim {
  position: absolute;
  top: 23px;
  left: 0;
  pointer-events: none;
  transition: all .3s ease;
}

input:focus + label.anim {
  top: 5px;
  transition: all .3s ease;
}

input:not([type=checkbox]):-webkit-autofill, input:not([type=checkbox]):focus{
  box-shadow: 0 0 0 1000px #E8EEF4 inset !important;
}

/* `Responsive
-------------------------------------------------------------*/
@include sm{
  header, main, footer{
    width: 75%;
  }
}

@include md{
  .form{
    width: 70%;
    margin: 0 auto;
  }
}

@include lg{
  header{
    flex-wrap: nowrap;
  }

  .title{
    font-size: $font-size-title;
    max-width: 600px;
    margin: 0 auto 100px;
    &--estimate{
      max-width: 650px;
      position: absolute;
      top: 100px;
      left: 100px;
      margin: 0;
    }
    &--left{
      text-align: left;
      margin: 0;
      max-width: 490px;
    }
    &--right{
      text-align: right;
      max-width: 330px;
    }
  }

  .btn--marge{
    margin: -36.5px auto 0;
    z-index: 2;
    position: relative;
  }

  .form{
    width: 60%;
    margin: 0;
  }
}

.btn-negatif {
  display: block;
  z-index: 2;
  position: relative;
  transform: translateY(-50%);
}

.btn-rdv, .btn-estimate {
  img {
    width: 100%;
    height: auto;
    @media screen and (min-width: 768px) {
      height: 60px;
      width: auto;
    }
  }
}

@include xl{
}
